import React from 'react';
import { DrawIt } from '../../../game/game';
import { HapticButton, NativeKit } from '../../../nativekit/nativekit';
import VIPRequired from './components/VIPRequired';

export default class ViewerView extends React.Component<ViewerView.Props, ViewerView.State> {
    state = {
        mode: DrawIt.ViewerMode.Reveal,
    };

    private _setMode(mode: DrawIt.ViewerMode) {
        this.setState({ mode });
        if (mode === DrawIt.ViewerMode.Guessing) {
            NativeKit.Live.PlayerPosition.setPIP({
                alignment: 'topRight',
                opacity: 0.5,
                height: 192,
            });
            NativeKit.TextField.focus({
                hintText: 'Guess the drawing...',
                textInputAction: 'done',
            });
        } else {
            NativeKit.Live.PlayerPosition.setPIP({
                alignment: 'topRight',
                opacity: 1.0,
                height: 192,
            });
            NativeKit.TextField.blur({
                hintText: '',
                textInputAction: 'done',
            });
        }
    }

    componentDidMount() {
        DrawIt.initViewerCanvas();
        this._setMode(DrawIt.ViewerMode.Reveal);
    }

    private _viewerGuessing() {
        return (
            <div style={{
                height: '100vh',
                fontSize: 18,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#fff',
            }}>

                <div style={{
                    width: '70vw',
                    height: '70vw',
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #000000CC',
                    borderRadius: 18.0,
                    display: 'flex',
                    flexWrap: 'wrap',
                    overflow: 'hidden',
                }}>
                    <canvas
                        id="viewer-canvas"
                        width={window.innerWidth * 0.9}
                        height={window.innerWidth * 0.9}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}>
                    </canvas>
                </div>
                {this.state.mode === DrawIt.ViewerMode.Guessing && (
                    <h1 style={{ marginTop: 4, marginBottom: 0 }}>??????</h1>
                )}
                {this.state.mode === DrawIt.ViewerMode.Reveal && (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <h1 style={{ marginTop: 4, marginBottom: 0 }}>renegade</h1>
                        <p>102 people guessed correctly</p>
                        <br />
                        <HapticButton>
                            Suggest a word
                        </HapticButton>
                    </div>
                )}
                {/* <VIPRequired /> */}
            </div>
        );
    }

    render() {
        switch (this.state.mode) {
            case DrawIt.ViewerMode.Guessing:
                return this._viewerGuessing();
            case DrawIt.ViewerMode.Reveal:
                return this._viewerGuessing();
        }
        return this._viewerGuessing();
    }
}

export namespace ViewerView {
    export interface Props {
    }
    export interface State {
        mode: DrawIt.ViewerMode;
    }
}