import { NativeChannel } from "../channel";

export class NativeKitLivePlayerPosition {
    public static async setFullscreen(): Promise<void> {
        await this._sendPosition('fullscreen', {
            alignment: 'center',
        });
    }

    public static async setPIP(options: NativeKitLivePlayerPosition.Options): Promise<void> {
        await this._sendPosition('pip', options);
    }

    private static _sendPosition(mode: string, options: NativeKitLivePlayerPosition.Options): Promise<void> {
        return NativeChannel.sendData('nativekit_live_player_position', {
            mode,
            ...options,
        });
    }
}

export namespace NativeKitLivePlayerPosition {
    export interface Options {
        alignment: string;
        opacity?: number;
        height?: number;
    }
}