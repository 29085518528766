import React from 'react';
import { NativeKitHaptic } from '../lib/haptic';

export default class NativeKitHapticButton extends React.Component<NativeButton.Props, NativeButton.State> {
    state = { isMouseDown: false };

    private _onTouchStart(): void {
        this.setState({ isMouseDown: true });
        NativeKitHaptic.selectionClick();
    }

    private _onTouchEnd(): void {
        this.setState({ isMouseDown: false });
        NativeKitHaptic.selectionClick();
    }

    render() {
        return (
            <div
                style={{
                    backgroundColor: '#FF4E6A',
                    backdropFilter: 'blur(10px)',
                    borderRadius: 9,
                    padding: '15px 36px',
                    color: '#fff',
                    fontSize: 18,
                    fontWeight: 600,
                    transition: '.15s ease-in-out',
                    transform: this.state.isMouseDown ? 'scale(0.9)' : 'scale(1.0)',
                    userSelect: 'none',
                    WebkitUserSelect: 'none',
                    textAlign: 'center',
                    ...(this.props.style ?? {}),
                }}
                onTouchStart={this._onTouchStart.bind(this)}
                onTouchEnd={this._onTouchEnd.bind(this)}
                onClick={this.props.onClick}
            >
                <span style={{ pointerEvents: 'none' }}>
                    {this.props.children}
                </span>
            </div >
        );
    }
}

export namespace NativeButton {
    export interface Props extends React.PropsWithChildren {
        style?: React.CSSProperties;
        onClick?: () => void;
    }
    export interface State {
        isMouseDown: boolean;
    }
}