import { v4 } from "uuid";

declare const window: any;

if (typeof window !== 'undefined')
    window.NativeKitResolvers = {};

export class NativeChannel {
    public static sendData<T = void>(type: string, data: any, timeout?: number): Promise<T> {
        const _messageId = v4();
        return new Promise((resolve) => {
            let _isResolved = false;

            if (typeof window !== 'undefined' && window.NativeKitResolvers)
                window.NativeKitResolvers[_messageId] = (data: any) => {
                    _isResolved = true;
                    resolve(data);
                    delete window.NativeKitResolvers[_messageId];
                };

            if (typeof window !== 'undefined' && window.NativeKitChannel)
                window.NativeKitChannel.postMessage(JSON.stringify({
                    id: _messageId,
                    type,
                    data,
                }));

            if (timeout)
                setTimeout(() => {
                    if (!_isResolved) resolve(null as any);
                }, timeout);
        });
    }
}