import { io, Socket } from 'socket.io-client';
import { NativeKit } from '../nativekit/nativekit';

let _socket: Socket;

setTimeout(async () => {
    console.log('init');
    const livestream = await NativeKit.Live.LivestreamData.getLivestream()
    console.log('livestream', livestream);
    const _token = await NativeKit.User.getToken();
    console.log('token', _token);
    _socket = io(livestream?.connectInfo.url ?? 'wss://nk-drawit-server.ngrok.io', {
        query: {
            authorization: _token.token,
            appId: livestream?.connectInfo.appId,
            channel: livestream?.connectInfo.channel,
            mode: 'streamer',
        },
        transports: ['websocket'],
    });


    _socket.on('draw', changes => DrawIt.updateDrawing(changes));
    _socket.on('clear', () => DrawIt.clearViewerCanvas());
    _socket.on('set-word', word => DrawIt.setWord(word));
    _socket.on('set-timer-end', time => DrawIt.setEndTime(time));
    _socket.on('top-scores', scores => DrawIt.setTopScores(scores));

    _socket.on('incorrect-guess', guess => DrawIt.addWrongGuess(guess));
    _socket.on('correct-guess', guess => DrawIt.addCorrectGuess(guess));
}, 500);

export class DrawIt {
    private static _viewerCanvas: DrawIt.Canvas | undefined;
    private static _streamerCanvas: DrawIt.Canvas | undefined;

    private static _currentWord: string = '';
    private static _endTime: Date | undefined;

    private static _topScores: DrawIt.TopScore[] = [];

    public static wrongGuesses: string[] = [];
    public static correctGuesses: string[] = [];

    public static onUpdate(cb: () => void): void {
        document.addEventListener('drawit-update', () => cb());
    }

    public static onEndTimeUpdate(cb: (endTime: Date) => void): void {
        document.addEventListener('drawit-end-time-update', () => cb(this.endTime));
    }

    public static addWrongGuess(guess: string): void {
        DrawIt.wrongGuesses.push(guess);
        document.dispatchEvent(new Event('drawit-update'));
    }
    public static addCorrectGuess(guess: string): void {
        DrawIt.correctGuesses.push(guess);
        document.dispatchEvent(new Event('drawit-update'));
    }

    public static setWord(word: string): void {
        this._currentWord = word;
        document.dispatchEvent(new Event('drawit-update'));
    }

    public static setEndTime(time: string): void {
        this._endTime = new Date(time);
        document.dispatchEvent(new Event('drawit-update'));
        document.dispatchEvent(new Event('drawit-end-time-update'));
    }

    public static setTopScores(scores: DrawIt.TopScore[]): void {
        this._topScores = scores;
        document.dispatchEvent(new Event('drawit-update'));
    }

    public static get currentWord(): string {
        return this._currentWord;
    }

    public static get topScores(): DrawIt.TopScore[] {
        return this._topScores;
    }

    public static streamerDraw(changes: DrawIt.StrokeChanges): void {
        _socket.emit('streamer-draw', changes);
    }

    public static streamerClear(): void {
        DrawIt.wrongGuesses = [];
        DrawIt.correctGuesses = [];
        this.streamerCanvas?.clear();
        _socket.emit('streamer-clear');
    }

    public static streamerNextWord(): void {
        _socket.emit('streamer-next');
    }

    public static initViewerCanvas(): void {
        this._viewerCanvas = new DrawIt.Canvas(document.getElementById('viewer-canvas') as HTMLCanvasElement);
    }

    public static initStreamerCanvas(): void {
        this._streamerCanvas = new DrawIt.Canvas(document.getElementById('streamer-canvas') as HTMLCanvasElement);
    }

    public static clearViewerCanvas(): void {
        this.viewerCanvas?.clear();
    }

    public static get viewerCanvas(): DrawIt.Canvas | undefined {
        return this._viewerCanvas;
    }

    public static get streamerCanvas(): DrawIt.Canvas | undefined {
        return this._streamerCanvas;
    }

    public static get endTime(): Date {
        return this._endTime ?? new Date();
    }

    public static updateDrawing(changes: DrawIt.StrokeChanges): void {
        changes.forEach(change => this.viewerCanvas?.stroke({
            previous: {
                x: change[0],
                y: change[1],
            },
            current: {
                x: change[2],
                y: change[3],
            },
        }));
    }
}

export namespace DrawIt {
    export interface TopScore {
        name: string;
        time: number;
    }
    export enum ViewerMode {
        Waiting = 'waiting',
        Guessing = 'guessing',
        Reveal = 'reveal',
    }
    export type StrokeChanges = number[][];
    export interface Stroke {
        previous: {
            x: number;
            y: number;
        };
        current: {
            x: number;
            y: number;
        };
    }
    export class Canvas {

        private _prevX = 0;
        private _currX = 0;
        private _prevY = 0;
        private _currY = 0;
        private _flag = false;
        private _dotFlag = false;

        private _ctx: CanvasRenderingContext2D | null;

        private _changes: StrokeChanges = [];

        public stroke({ previous, current }: Stroke) {
            if (this._ctx) {
                console.log('draw');
                this._ctx.beginPath();
                this._ctx.moveTo(previous.x, previous.y);
                this._ctx.lineTo(current.x, current.y);
                this._ctx.strokeStyle = 'black';
                this._ctx.lineWidth = 4;
                this._ctx.stroke();
                this._ctx.closePath();
                this._changes.push([this._prevX, this._prevY, this._currX, this._currY].map(a => Math.floor(a)));
            }
        }

        private _draw() {
            this.stroke({
                previous: {
                    x: this._prevX,
                    y: this._prevY,
                },
                current: {
                    x: this._prevX,
                    y: this._prevY,
                },
            })
            if (this._ctx) {
                console.log('draw');
                this._ctx.beginPath();
                this._ctx.moveTo(this._prevX, this._prevY);
                this._ctx.lineTo(this._currX, this._currY);
                this._ctx.strokeStyle = 'black';
                this._ctx.lineWidth = 4;
                this._ctx.stroke();
                this._ctx.closePath();
                this._changes.push([this._prevX, this._prevY, this._currX, this._currY].map(a => Math.floor(a)));
            }
        }

        public findXY(res: string, pos: { x: number, y: number }) {
            if (!this._canvas || !this._ctx) return;

            if (res === 'down') {
                this._prevX = this._currX;
                this._prevY = this._currY;
                this._currX = pos.x - this._canvas.offsetLeft;
                this._currY = pos.y - this._canvas.offsetTop;

                this._flag = true;
                this._dotFlag = true;
                if (this._dotFlag) {
                    this._ctx.beginPath();
                    this._ctx.fillStyle = 'black';
                    this._ctx.fillRect(this._currX, this._currY, 2, 2);
                    this._ctx.closePath();
                    this._dotFlag = false;
                }
            }
            if (res === 'up' || res === "out") {
                this._flag = false;
                DrawIt.streamerDraw(this._changes);
                this._changes = [];
            }
            if (res === 'move') {
                if (this._flag) {
                    this._prevX = this._currX;
                    this._prevY = this._currY;
                    this._currX = pos.x - this._canvas.offsetLeft;
                    this._currY = pos.y - this._canvas.offsetTop;
                    this._draw();
                }
            }
        }

        constructor(private _canvas: HTMLCanvasElement) {
            this._ctx = _canvas?.getContext('2d');
        }

        public clear(): void {
            this._ctx?.clearRect(0, 0, this._canvas.width, this._canvas.height);
        }

        public get ctx(): CanvasRenderingContext2D | null {
            return this._ctx;
        }

        public get canvas(): HTMLCanvasElement {
            return this._canvas;
        }
    }
}
