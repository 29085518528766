import { NativeChannel } from "./channel";

export class NativeKitUser {
    public static async getToken(): Promise<NativeKitUser.Token> {
        const _response = await this._sendEvent({
            action: 'getToken',
        });
        return _response.token;
    }

    private static _sendEvent(options: NativeKitUser.Options): Promise<NativeKitUser.RawResponse> {
        return NativeChannel.sendData<NativeKitUser.RawResponse>('nativekit_user', options);
    }
}

export namespace NativeKitUser {
    export interface Options {
        action: string;
    }
    export interface Token {
        token: string;
        expiresAt: string;
    }
    export interface RawResponse {
        success: boolean;
        token: Token;
    }
}