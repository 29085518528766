import { NativeChannel } from "./channel";

export class NativeKitEntitlements {
    public static async hasEntitlement(): Promise<boolean> {
        const _response = await this._sendEvent({
            action: 'hasEntitlement',
        });
        return Boolean(_response.hasEntitlement);
    }

    public static async showPurchaseModal(): Promise<boolean> {
        const _response = await this._sendEvent({
            action: 'showPurchaseModal',
        });
        return Boolean(_response.success);
    }

    private static _sendEvent(options: NativeKitEntitlements.Options): Promise<NativeKitEntitlements.RawResponse> {
        return NativeChannel.sendData<NativeKitEntitlements.RawResponse>('nativekit_entitlements', options);
    }
}

export namespace NativeKitEntitlements {
    export interface Options {
        action: string;
    }
    export interface RawResponse {
        success: boolean;
        hasEntitlement?: boolean;
    }
}