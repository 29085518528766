import React from "react";

export default class CountdownTimer extends React.Component<CountdownTimer.Props, CountdownTimer.State> {
    state = { timeLeft: this.props.endTime.getTime() - Date.now() };
    private _interval: any;

    componentDidMount() {
        this._interval = setInterval(() => {
            this.setState({ timeLeft: this.props.endTime.getTime() - Date.now() });
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this._interval);
    }

    render() {
        const _minutes = Math.floor(this.state.timeLeft / 1000 / 60);
        const _seconds = Math.floor(this.state.timeLeft / 1000) % 60;
        return this.state.timeLeft > 0 ? (
            <div style={{
                fontSize: 18.0,
                ...this.props.style,
            }}>
                {_minutes}:{_seconds < 10 ? '0' : ''}{_seconds}
            </div>
        ) : (<></>);
    }
}

export namespace CountdownTimer {
    export interface Props {
        style?: React.CSSProperties;
        endTime: Date;
    }
    export interface State {
        timeLeft: number;
    }
}