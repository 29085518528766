import { NativeChannel } from "./channel";

export class NativeKitTextField {
    public static async focus(options: NativeKitTextField.Options): Promise<void> {
        this._sendAction('focus', options);
    }

    public static async blur(options: NativeKitTextField.Options): Promise<void> {
        this._sendAction('blur', options);
    }

    private static _sendAction(action: string, options: NativeKitTextField.Options): void {
        NativeChannel.sendData('nativekit_textfield', {
            action,
            options: {
                ...options,
                textInputAction: 'done',
            },
        });
    }
}

export namespace NativeKitTextField {
    export interface Options {
        hintText: string;
        textInputAction?: string;
    }
}