import React from 'react';
import CountdownTimer from '../../../components/CountdownTimer';
import { DrawIt } from '../../../game/game';
import { HapticButton } from '../../../nativekit/nativekit';

enum StreamerMode {
    Initial = 'initial',
    Drawing = 'drawing',
    Complete = 'complete',
}

export default class StreamerView extends React.Component<StreamerView.Props, StreamerView.State> {

    state = { mode: StreamerMode.Initial, wrongGuesses: [], correctGuesses: [] };

    componentDidMount() {
        DrawIt.onUpdate(() => this.setState({}));
        DrawIt.onEndTimeUpdate((endTime) =>
            setTimeout(() => {
                if (this.state.mode == StreamerMode.Drawing)
                    this.setState({ mode: StreamerMode.Complete });
            }, endTime.getTime() - Date.now()));
    }

    render() {
        if (this.state.mode === StreamerMode.Initial) {
            return (
                <div style={{
                    userSelect: 'none',
                    WebkitUserSelect: 'none',
                    height: '100vh',
                    fontSize: 18,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',

                }}>
                    <div style={{
                        padding: '27px 45px',
                        backgroundColor: '#fff',
                        borderRadius: 18,
                        border: '0.5px solid #00000077',
                    }}>
                        <img src="/draw_it_logo.png" height="96" />
                        <div style={{
                            textAlign: 'center',
                            marginBottom: 27,
                        }}>
                            Have your viewers guess<br />
                            what you're drawing!
                        </div>
                        <HapticButton onClick={() => {
                            this.setState({ mode: StreamerMode.Drawing });
                            DrawIt.streamerNextWord();
                            setTimeout(() => DrawIt.initStreamerCanvas(), 100);
                        }}>
                            start game
                        </HapticButton>
                    </div>
                </div>
            );
        }
        if (this.state.mode === StreamerMode.Complete) {
            return (
                <div style={{
                    userSelect: 'none',
                    WebkitUserSelect: 'none',
                    height: '100vh',
                    fontSize: 18,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',

                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '18px 0px',
                        backgroundColor: '#fff',
                        borderRadius: 18,
                        border: '0.5px solid #00000077',
                        width: '95vw',
                    }}>

                        <h1 style={{
                            textAlign: 'center',
                            fontSize: 27,
                            marginTop: 0,
                        }}>Time&apos;s up!</h1>

                        <HapticButton
                            onClick={() => {
                                this.setState({ mode: StreamerMode.Drawing });
                                DrawIt.streamerNextWord();
                                DrawIt.streamerClear();
                                setTimeout(() => DrawIt.initStreamerCanvas(), 100);
                            }}
                            style={{
                                marginTop: 9,
                                maxWidth: 350,
                                width: '90vw',
                                padding: '15px 0',
                            }}>
                            next word
                        </HapticButton>
                    </div>
                    <div style={{
                        userSelect: 'none',
                        WebkitUserSelect: 'none',
                        marginTop: 18,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '18px 0px',
                        backgroundColor: '#fff',
                        borderRadius: 18,
                        border: '0.5px solid #00000077',
                        width: '95vw',
                    }}>
                        {DrawIt.topScores.length > 0 && (
                            <>
                                <h2 style={{
                                    fontSize: 22,
                                    marginTop: 0,
                                    marginBottom: 0,
                                }}>Fastest guesses</h2>
                                <h3 style={{
                                    fontSize: 18,
                                    marginTop: 0,
                                    color: '#00000077',
                                }}>tip: shout them out!</h3>
                                <div style={{
                                    textAlign: 'left',
                                }}>
                                    {DrawIt.topScores.map((score, i) => (
                                        <div>
                                            {i + 1}. {score.name} ({(score.time / 1000).toFixed(1)}s)
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                        {DrawIt.topScores.length === 0 && (
                            <>
                                <h2 style={{
                                    fontSize: 18,
                                    marginTop: 0,
                                    marginBottom: 4,
                                }}>Nobody guessed the word this time.</h2>
                                <h3 style={{
                                    textAlign: 'center',
                                    fontSize: 15,
                                    marginTop: 0,
                                    marginBottom: 0,
                                    color: '#00000077',
                                    padding: '0 27px',
                                }}>{(() => {
                                    const opts = [
                                        `Come on! Let's put that art degree to work!`,
                                        `You know what they say, practice makes perfect. Looks like you need more practice!`,
                                        `Not sure if that was a drawing or a Rorschach test.`,
                                        `Not sure if that was a drawing or an attempt at abstract art.`,
                                        `If we get enough gifts this live, we could send you to drawing school.`,
                                        `You might have just invented a new art form, but unfortunately, nobody has a clue what it is.`
                                    ];
                                    return opts[Math.floor(Math.random() * opts.length)];
                                })()}</h3>
                            </>
                        )}
                    </div>
                </div>
            );
        }

        return (
            <div style={{
                userSelect: 'none',
                WebkitUserSelect: 'none',
                height: '100vh',
                fontSize: 18,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                {DrawIt.wrongGuesses.map((guess, ind) => (
                    <div key={`${guess}-${ind}`} style={{
                        position: 'fixed',
                        pointerEvents: 'none',
                        animation: '2s wrong-text',
                        opacity: 0,
                    }}>{guess}</div>
                ))}
                {DrawIt.correctGuesses.map((guess, ind) => (
                    <div key={`${guess}-${ind}`} style={{
                        position: 'fixed',
                        pointerEvents: 'none',
                        animation: '3s success-text',
                        opacity: 0,
                    }}>{guess}</div>
                ))}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '18px 0px',
                    backgroundColor: '#fff',
                    borderRadius: 18,
                    border: '0.5px solid #00000077',
                    width: '95vw',
                }}>

                    <h1 style={{
                        textAlign: 'center',
                        fontSize: 27,
                        marginTop: 0,
                    }}>Draw "{DrawIt.currentWord}"</h1>

                    <div style={{
                        maxWidth: 350,
                        maxHeight: 350,
                        width: '90vw',
                        height: '90vw',
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #000000CC',
                        borderRadius: 18.0,
                        display: 'flex',
                        flexWrap: 'wrap',
                        overflow: 'hidden',
                    }}>
                        <canvas
                            id="streamer-canvas"
                            onTouchMove={(e) => DrawIt.streamerCanvas?.findXY('move', { x: e.touches[0].clientX, y: e.touches[0].clientY })}
                            onTouchStart={(e) => DrawIt.streamerCanvas?.findXY('down', { x: e.touches[0].clientX, y: e.touches[0].clientY })}
                            onTouchEnd={(e) => DrawIt.streamerCanvas?.findXY('up', { x: e.touches[0]?.clientX, y: e.touches[0]?.clientY })}
                            width={window.innerWidth * 0.9}
                            height={window.innerWidth * 0.9}
                            style={{
                                width: '100%',
                                height: '100%',
                            }}>
                        </canvas>

                    </div>
                    <HapticButton
                        onClick={() => {
                            DrawIt.streamerClear();
                        }}
                        style={{
                            marginTop: 9,
                            fontSize: 12.0,
                            maxWidth: 350,
                            width: '90vw',
                            padding: '15px 0',
                        }}>
                        clear
                    </HapticButton>
                    {/* <br />
                    <HapticButton
                        onClick={() => {
                            DrawIt.streamerNextWord();
                        }}
                        style={{
                            marginTop: 9,
                            fontSize: 12.0,
                            maxWidth: 350,
                            width: '30vw',
                            padding: '15px 0',
                        }}>
                        skip word
                    </HapticButton> */}
                </div>

                <div style={{
                    marginTop: 27,
                    padding: '18px 36px',
                    backgroundColor: '#fff',
                    borderRadius: 18,
                    border: '0.5px solid #00000077',
                }}>
                    <CountdownTimer
                        style={{
                            fontSize: 27,
                            fontWeight: 700,
                        }}
                        endTime={DrawIt.endTime}
                    />
                </div>
                <div style={{
                    height: 128,
                    overflowY: 'auto',
                    marginTop: 18,
                }}>
                    {DrawIt.correctGuesses.map((guess, ind) => (
                        <div key={`${guess}-list-${ind}`} style={{
                            color: '#fff',
                            fontSize: 18.0,
                        }}>{guess}</div>
                    ))}
                </div>


            </div >
        );
    }
}

export namespace StreamerView {
    export interface Props {
    }
    export interface State {
        mode: StreamerMode;
        wrongGuesses: string[];
        correctGuesses: string[];
    }
}