import { NativeKitSystemCallback } from "./callbacks/system-callback";
import NativeKitHapticButton from "./components/HapticButton";
import { NativeKitAds } from "./lib/ads";
import { NativeKitDialogs } from "./lib/dialogs";
import { NativeKitEntitlements } from "./lib/entitlements";
import { NativeKitHaptic } from "./lib/haptic";
import { NativeKitLivestreamData } from "./lib/live/live-data";
import { NativeKitLivePlayerPosition } from "./lib/live/player-position";
import { NativeKitLocalStorage } from "./lib/local-storage";
import { NativeKitPurchases } from "./lib/purchases";
import { NativeKitTextField } from "./lib/text-field";
import { NativeKitUser } from "./lib/user";

export class NativeKit {

}

export namespace NativeKit {
    export type Haptic = NativeKitHaptic;
    export const Haptic = NativeKitHaptic;
    export type Ads = NativeKitAds;
    export const Ads = NativeKitAds;
    export type Dialogs = NativeKitDialogs;
    export const Dialogs = NativeKitDialogs;
    export type LocalStorage = NativeKitLocalStorage;
    export const LocalStorage = NativeKitLocalStorage;
    export type SystemCallback = NativeKitSystemCallback;
    export const SystemCallback = NativeKitSystemCallback;
    export type Purchases = NativeKitPurchases;
    export const Purchases = NativeKitPurchases;
    export type TextField = NativeKitTextField;
    export const TextField = NativeKitTextField;
    export type Entitlements = NativeKitEntitlements;
    export const Entitlements = NativeKitEntitlements;
    export type User = NativeKitUser;
    export const User = NativeKitUser;
    export namespace Live {
        export type PlayerPosition = NativeKitLivePlayerPosition;
        export const PlayerPosition = NativeKitLivePlayerPosition;
        export type LivestreamData = NativeKitLivestreamData;
        export const LivestreamData = NativeKitLivestreamData;
    }
}

export type HapticButton = NativeKitHapticButton;
export const HapticButton = NativeKitHapticButton;