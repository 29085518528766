import { NativeChannel } from "../channel";

export class NativeKitLivestreamData {
    public static async getLivestream(): Promise<NativeKitLivestreamData.Livestream | null> {
        const _response = await this._sendEvent({});
        return _response;
    }

    private static _sendEvent(options: NativeKitLivestreamData.Options): Promise<NativeKitLivestreamData.RawResponse> {
        return NativeChannel.sendData<NativeKitLivestreamData.RawResponse>('nativekit_livestream_data', options);
    }
}

export namespace NativeKitLivestreamData {
    export interface Options {
    }
    export type RawResponse = Livestream;
    export interface Livestream {
        id: string;
        connectInfo: ConnectInfo;
    }
    export interface ConnectInfo {
        id: string;
        url: string;
        appId: string;
        channel: string;
    }
}